// src/components/Dashboard.js
import React from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import '../styles/Dashboard.css';

const Dashboard = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    auth.signOut();
    navigate("/");
  };

  return (
    <div className="dashboard-container">
      <h1 className="dashboard-title">Admin Dashboard</h1>

      {/* Button Cards */}
      <button
        className="dashboard-button"
        onClick={() => navigate("/manage-testimonials")}
      >
        Manage Testimonials
      </button>

      <button
        className="dashboard-button"
        onClick={() => navigate("/admin-hero")}
      >
        Hero
      </button>

      <button
        className="dashboard-button"
        onClick={() => navigate("/retailer-panel")}
      >
        Retailer Panel
      </button>

      <button
        className="dashboard-button"
        onClick={() => navigate("/author-admin")}
      >
        Meet the Author
      </button>

      <button
        className="dashboard-button"
        onClick={() => navigate("/reader-opinion")}
      >
        Reader's Opinion
      </button>

      <button
        className="dashboard-button"
        onClick={() => navigate("/social-media")}
      >
        Social Media
      </button>

      <button
        className="dashboard-button"
        onClick={() => navigate("/event-page")}
      >
        Event Page
      </button>

      <button
        className="dashboard-button"
        onClick={() => navigate("/link-manager")}
      >
        Get Your Copy
      </button>

      <button
        className="dashboard-button"
        onClick={() => navigate("/kindle-manager")}
      >
        Kindle Manager
      </button>

      <button
        className="dashboard-button"
        onClick={() => navigate("/change-password")}
      >
        Change Password
      </button>

      <button className="dashboard-button" onClick={handleLogout}>
        Logout
      </button> 
    </div>
  );
};

export default Dashboard;
