import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";
import '../styles/testimonial.css';

const ManageTestimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [formData, setFormData] = useState({ text: "", author: "", title: "" });
  const [editingTestimonialId, setEditingTestimonialId] = useState(null);
  const db = getFirestore();

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const fetchTestimonials = async () => {
    const snapshot = await getDocs(collection(db, "testimonials"));
    const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setTestimonials(data);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const testimonialData = {
      text: formData.text,
      author: formData.author,
      title: formData.title,
    };
    if (editingTestimonialId) {
      await updateDoc(doc(db, "testimonials", editingTestimonialId), testimonialData);
      setEditingTestimonialId(null);
    } else {
      await addDoc(collection(db, "testimonials"), testimonialData);
    }
    fetchTestimonials();
    setFormData({ text: "", author: "", title: "" });
  };

  const handleEdit = (testimonial) => {
    setEditingTestimonialId(testimonial.id);
    setFormData({ text: testimonial.text, author: testimonial.author, title: testimonial.title });
  };

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, "testimonials", id));
    fetchTestimonials();
  };

  return (
    <div className="testimonial-container">
      <h1 className="main-title">Manage Testimonials</h1>
      <h2 className="form-heading">{editingTestimonialId ? "Edit" : "Add"} Testimonial</h2>
      <form onSubmit={handleSubmit} className="testimonial-form">
        <textarea
          name="text"
          value={formData.text}
          onChange={handleInputChange}
          placeholder="Testimonial Text"
          required
          className="form-textarea"
        />
        <input
          type="text"
          name="author"
          value={formData.author}
          onChange={handleInputChange}
          placeholder="Author"
          required
          className="form-input"
        />
        <input
          type="text"
          name="title"
          value={formData.title}
          onChange={handleInputChange}
          placeholder="Title"
          required
          className="form-input"
        />
        <button type="submit" className="form-button">
          {editingTestimonialId ? "Update" : "Add"} Testimonial
        </button>
      </form>

      <h2 className="section-heading">Testimonials</h2>
      <div className="testimonial-list">
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="testimonial-card">
            <blockquote className="testimonial-text">{testimonial.text}</blockquote>
            <p className="testimonial-author">
              - {testimonial.author}, <span className="testimonial-title">{testimonial.title}</span>
            </p>
            <div className="button-group">
              <button onClick={() => handleEdit(testimonial)} className="edit-button">Edit</button>
              <button onClick={() => handleDelete(testimonial.id)} className="delete-button">Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManageTestimonials;
