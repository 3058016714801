import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";
import '../styles/Author.css';

const AuthorAdmin = () => {
  const [authors, setAuthors] = useState([]);
  const [newAuthor, setNewAuthor] = useState({ title: "", description: "", slogan: "", image: null });
  const [editingId, setEditingId] = useState(null);
  const [existingImage, setExistingImage] = useState(null);

  useEffect(() => {
    const fetchAuthors = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "authors"));
        setAuthors(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error("Error fetching authors: ", error);
      }
    };

    fetchAuthors();
  }, []);

  const handleImageUpload = async (file) => {
    try {
      if (!file.type.startsWith("image/")) {
        alert("Please select a valid image file.");
        return null;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
      });
    } catch (error) {
      console.error("Error processing image: ", error);
      return null;
    }
  };

  const addOrUpdateAuthor = async () => {
    try {
      let imageURL = existingImage; // Use existing image by default
      if (newAuthor.image) {
        imageURL = await handleImageUpload(newAuthor.image);
      }

      const authorData = {
        title: newAuthor.title,
        description: newAuthor.description,
        slogan: newAuthor.slogan,
        image: imageURL,
      };

      if (editingId) {
        // Update author
        await updateDoc(doc(db, "authors", editingId), authorData);
        setAuthors(authors.map((author) => (author.id === editingId ? { id: editingId, ...authorData } : author)));
        setEditingId(null);
      } else {
        // Add new author
        const docRef = await addDoc(collection(db, "authors"), authorData);
        setAuthors([...authors, { id: docRef.id, ...authorData }]);
      }

      setNewAuthor({ title: "", description: "", slogan: "", image: null });
      setExistingImage(null);
    } catch (error) {
      console.error("Error adding/updating author: ", error);
    }
  };

  const deleteAuthor = async (id) => {
    try {
      await deleteDoc(doc(db, "authors", id));
      setAuthors(authors.filter((author) => author.id !== id));
    } catch (error) {
      console.error("Error deleting author: ", error);
    }
  };

  const startEditing = (author) => {
    setEditingId(author.id);
    setExistingImage(author.image); // Retain the current image
    setNewAuthor({
      title: author.title,
      description: author.description,
      slogan: author.slogan,
      image: null, // Reset image input for a new upload
    });
  };

  return (
    <div>
      <h1>Author Administration</h1>
      <div>
        <input
          type="text"
          placeholder="Title"
          value={newAuthor.title}
          onChange={(e) => setNewAuthor({ ...newAuthor, title: e.target.value })}
        />
        <textarea
          placeholder="Description"
          value={newAuthor.description}
          onChange={(e) => setNewAuthor({ ...newAuthor, description: e.target.value })}
        />
        <input
          type="text"
          placeholder="Slogan"
          value={newAuthor.slogan}
          onChange={(e) => setNewAuthor({ ...newAuthor, slogan: e.target.value })}
        />
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setNewAuthor({ ...newAuthor, image: e.target.files[0] })}
        />
        <button
          onClick={addOrUpdateAuthor}
          disabled={authors.length > 0 && !editingId} // Disable if there's already one author and not in editing mode
        >
          {editingId ? "Update Author" : "Add Author"}
        </button>
      </div>
      <ul>
        {authors.map((author) => (
          <li key={author.id} style={{ marginBottom: "20px", listStyle: "none" }}>
            <h3>{author.title}</h3>
            <p>{author.description}</p>
            <p>Slogan : {author.slogan}</p>
            {author.image && (
              <div style={{ maxWidth: "200px", textAlign: "center" }}>
                <img
                  src={author.image}
                  alt={author.title}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "cover",
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                  }}
                />
              </div>
            )}
            <button onClick={() => startEditing(author)}>Edit</button>
            <button onClick={() => deleteAuthor(author.id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AuthorAdmin;
