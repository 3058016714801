import React, { useState, useEffect } from "react";
import { db } from "../firebase"; // Import Firestore
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";
import '../styles/Retailer.css';

const RetailerPanel = () => {
  const [retailers, setRetailers] = useState([]);
  const [newRetailer, setNewRetailer] = useState({ id: "", name: "", link: "", image: null });

  useEffect(() => {
    const fetchRetailers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "retailers"));
        setRetailers(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error("Error fetching retailers: ", error);
      }
    };

    fetchRetailers();
  }, []);

  const handleImageUpload = async (file) => {
    try {
      if (!file.type.startsWith("image/")) {
        alert("Please select a valid image file.");
        return null;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result);  // On success, resolve with the base64 URL
        reader.onerror = reject;  // On error, reject the promise
      });
    } catch (error) {
      console.error("Error processing image: ", error);
      return null;
    }
  };

  const addRetailer = async () => {
    try {
      let imageURL = null;

      if (newRetailer.image) {
        imageURL = await handleImageUpload(newRetailer.image);
      }

      const retailerData = { name: newRetailer.name, link: newRetailer.link, image: imageURL };
      const docRef = await addDoc(collection(db, "retailers"), retailerData);
      setRetailers([...retailers, { id: docRef.id, ...retailerData }]);
      setNewRetailer({ name: "", link: "", image: null });
    } catch (error) {
      console.error("Error adding retailer: ", error);
    }
  };

  const updateRetailer = async () => {
    try {
      let imageURL = null;

      if (newRetailer.image) {
        imageURL = await handleImageUpload(newRetailer.image);
      }

      const updatedData = { name: newRetailer.name, link: newRetailer.link, image: imageURL || newRetailer.image };
      console.log("Updating retailer with id:", newRetailer.id);
      console.log("Updated data:", updatedData);

      await updateDoc(doc(db, "retailers", newRetailer.id), updatedData);
      
      // Update the state with the updated retailer data
      setRetailers(retailers.map((retailer) => 
        retailer.id === newRetailer.id ? { id: retailer.id, ...updatedData } : retailer
      ));
      setNewRetailer({ id: "", name: "", link: "", image: null });
    } catch (error) {
      console.error("Error updating retailer: ", error);
    }
  };

  const deleteRetailer = async (id) => {
    try {
      console.log("Deleting retailer with id:", id);
      await deleteDoc(doc(db, "retailers", id));
      setRetailers(retailers.filter((retailer) => retailer.id !== id));
    } catch (error) {
      console.error("Error deleting retailer: ", error);
    }
  };

  const handleEditClick = (retailer) => {
    setNewRetailer({
      id: retailer.id,
      name: retailer.name,
      link: retailer.link,
      image: retailer.image
    });
  };

  return (
    <div className="retailer-panel-container">
      <h1>Retailer Panel</h1>

      {/* Scrollable Container */}
      <div className="scrollable-container">
        {/* Form to Add or Update Retailer */}
        <div className="form-container">
          <input
            type="text"
            placeholder="Name"
            value={newRetailer.name}
            onChange={(e) => setNewRetailer({ ...newRetailer, name: e.target.value })}
          />
          <input
            type="text"
            placeholder="Link"
            value={newRetailer.link}
            onChange={(e) => setNewRetailer({ ...newRetailer, link: e.target.value })}
          />
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setNewRetailer({ ...newRetailer, image: e.target.files[0] })}
          />
          {newRetailer.id ? (
            <button onClick={updateRetailer}>Update Retailer</button>
          ) : (
            <button onClick={addRetailer}>Add Retailer</button>
          )}
        </div>

        {/* Retailer Cards */}
        <div className="retailer-cards">
          {retailers.map((retailer) => (
            <div key={retailer.id} className="retailer-card">
              <img
                src={retailer.image || 'https://via.placeholder.com/100'}
                alt={retailer.name}
                className="retailer-image"
              />
              <div className="retailer-info">
                <h3>{retailer.name}</h3>
                <p>{retailer.link}</p>
                <div className="button-group">
                  <button
                    className="update-button"
                    onClick={() => handleEditClick(retailer)} // Fill form with existing data to edit
                  >
                    Edit
                  </button>
                  <button
                    className="delete-button"
                    onClick={() => deleteRetailer(retailer.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RetailerPanel;
