import React, { useState, useEffect } from "react";
import { db } from "../firebase"; // Import Firestore
import { collection, getDocs, addDoc, deleteDoc, doc } from "firebase/firestore";
import "../styles/Link.css"; // Import the CSS

const LinkManager = () => {
  const [links, setLinks] = useState([]);
  const [newLink, setNewLink] = useState("");

  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "links"));
        setLinks(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error("Error fetching links: ", error);
      }
    };

    fetchLinks();
  }, []);

  const addLink = async () => {
    if (!newLink) {
      alert("Please enter a valid link.");
      return;
    }

    try {
      const linkData = { link: newLink };
      const docRef = await addDoc(collection(db, "links"), linkData);
      setLinks([...links, { id: docRef.id, ...linkData }]);
      setNewLink("");
    } catch (error) {
      console.error("Error adding link: ", error);
    }
  };

  const deleteLink = async (id) => {
    try {
      await deleteDoc(doc(db, "links", id));
      setLinks(links.filter((link) => link.id !== id));
    } catch (error) {
      console.error("Error deleting link: ", error);
    }
  };

  return (
    <div>
      <h1>Get Your Copy</h1>
      <div className="link-form">
        <input
          type="text"
          placeholder="Enter Link"
          value={newLink}
          onChange={(e) => setNewLink(e.target.value)}
        />
        <button onClick={addLink}>Get Copy</button>
      </div>
      <div className="link-list">
        {links.map((link) => (
          <div className="link-item" key={link.id}>
            <a href={link.link} target="_blank" rel="noopener noreferrer">
              {link.link}
            </a>
            <button className="delete-button" onClick={() => deleteLink(link.id)}>
              Delete
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LinkManager;
