// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import ManageTestimonials from "./components/ManageTestimonials";
import AdminHero from "./components/AdminHero";
import RetailerPanel from "./components/RetailerPanel";
import AuthorAdmin from "./components/AuthorAdmin";
import ReaderOpinionAdmin from "./components/ReaderOpinionAdmin";
import SocialMediaPanel from "./components/SocialMediaPanel";
import EventAdminPanel from "./components/EventAdminPanel";
import LinkManager from "./components/LinkManager";
import KindleManager from "./components/KindleManager";
import ChangePassword from "./components/ChangePassword";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/manage-testimonials" element={<ManageTestimonials />} />
        <Route path="/admin-hero" element={<AdminHero />} />
        <Route path="/retailer-panel" element={<RetailerPanel />} />
        <Route path="/author-admin" element={<AuthorAdmin />} />
        <Route path="/reader-opinion" element={<ReaderOpinionAdmin />} />
        <Route path="/social-media" element={<SocialMediaPanel />} />
        <Route path="/event-page" element={<EventAdminPanel />} />
        <Route path="/link-manager" element={<LinkManager />} />
        <Route path="/kindle-manager" element={<KindleManager />} />
        <Route path="/change-password" element={<ChangePassword />} />

      </Routes>
    </Router> 
  );
}

export default App;
