import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";
import '../styles/Hero.css';

const AdminHero = () => {
  const [cards, setCards] = useState([]);
  const [formData, setFormData] = useState({ number: "", description: "" });
  const [editingCardId, setEditingCardId] = useState(null);
  const db = getFirestore();

  useEffect(() => {
    fetchHeroCards();
  }, []);

  const fetchHeroCards = async () => {
    const snapshot = await getDocs(collection(db, "heroData"));
    const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setCards(data);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const cardData = {
      number: formData.number,
      description: formData.description,
    };

    if (editingCardId) {
      await updateDoc(doc(db, "heroData", editingCardId), cardData);
      setEditingCardId(null);
    } else {
      await addDoc(collection(db, "heroData"), cardData);
    }

    fetchHeroCards();
    setFormData({ number: "", description: "" });
  };

  const handleEdit = (card) => {
    setEditingCardId(card.id);
    setFormData({ number: card.number, description: card.description });
  };

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, "heroData", id));
    fetchHeroCards();
  };

  return (
    <div className="hero-container">
      <h2 className="hero-title">{editingCardId ? "Edit" : "Add"} Hero Card</h2>
      <form onSubmit={handleSubmit} className="hero-form">
        <input
          type="text"
          name="number"
          value={formData.number}
          onChange={handleInputChange}
          placeholder="Number"
          required
          className="hero-input"
        />
        <input
          type="text"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          placeholder="Description"
          required
          className="hero-input"
        />
        <button type="submit" className="hero-button">
          {editingCardId ? "Update" : "Add"} Card
        </button>
      </form>

      <h2 className="cards-heading">Hero Cards</h2>
      <div className="hero-cards-list">
        {cards.map((card) => (
          <div key={card.id} className="hero-card">
            <p className="hero-card-text">{card.number}</p>
            <p className="hero-card-author">{card.description}</p>
            <div className="button-group">
              <button onClick={() => handleEdit(card)} className="edit-button">Edit</button>
              <button onClick={() => handleDelete(card.id)} className="delete-button">Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminHero;
