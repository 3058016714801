import React, { useState, useEffect } from "react";
import { db } from "../firebase"; // Import Firestore
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";
import '../styles/event.css';  // Import the CSS file

const EventAdminPanel = () => {
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({ name: "", description: "", date: "", location: "", image: null });
  const [editingId, setEditingId] = useState(null); // State to track the ID of the event being edited

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "events"));
        setEvents(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error("Error fetching events: ", error);
      }
    };

    fetchEvents();
  }, []);

  const handleImageUpload = async (file) => {
    try {
      if (!file.type.startsWith("image/")) {
        alert("Please select a valid image file.");
        return null;
      }

      // Convert image to base64 or store as a binary in Firestore
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result);  // On success, resolve with the base64 URL
        reader.onerror = reject;  // On error, reject the promise
      });
    } catch (error) {
      console.error("Error processing image: ", error);
      return null;
    }
  };

  const addEvent = async () => {
    try {
      let imageURL = null;

      if (newEvent.image) {
        imageURL = await handleImageUpload(newEvent.image);
      }

      const eventData = { name: newEvent.name, description: newEvent.description, date: newEvent.date, location: newEvent.location, image: imageURL };
      const docRef = await addDoc(collection(db, "events"), eventData);
      setEvents([...events, { id: docRef.id, ...eventData }]);
      setNewEvent({ name: "", description: "", date: "", location: "", image: null });
    } catch (error) {
      console.error("Error adding event: ", error);
    }
  };

  const updateEvent = async () => {
    try {
      let imageURL = null;
      if (newEvent.image) {
        imageURL = await handleImageUpload(newEvent.image);
      }

      const updatedData = { 
        name: newEvent.name, 
        description: newEvent.description, 
        date: newEvent.date, 
        location: newEvent.location, 
        image: imageURL || newEvent.image 
      };

      await updateDoc(doc(db, "events", editingId), updatedData);
      setEvents(events.map((event) => (event.id === editingId ? { id: editingId, ...updatedData } : event)));
      setNewEvent({ name: "", description: "", date: "", location: "", image: null });
      setEditingId(null); // Reset the editing ID
    } catch (error) {
      console.error("Error updating event: ", error);
    }
  };

  const deleteEvent = async (id) => {
    try {
      await deleteDoc(doc(db, "events", id));
      setEvents(events.filter((event) => event.id !== id));
    } catch (error) {
      console.error("Error deleting event: ", error);
    }
  };

  const handleEditClick = (event) => {
    setEditingId(event.id);
    setNewEvent({ name: event.name, description: event.description, date: event.date, location: event.location, image: event.image });
  };

  return (
    <div>
      <h1>Event Admin Panel</h1>
      <div>
        <input
          type="text"
          placeholder="Event Name"
          value={newEvent.name}
          onChange={(e) => setNewEvent({ ...newEvent, name: e.target.value })}
        />
        <textarea
          placeholder="Event Description"
          value={newEvent.description}
          onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
        />
        <input
          type="datetime-local"
          value={newEvent.date}
          onChange={(e) => setNewEvent({ ...newEvent, date: e.target.value })}
        />
        <input
          type="text"
          placeholder="Event Location"
          value={newEvent.location}
          onChange={(e) => setNewEvent({ ...newEvent, location: e.target.value })}
        />
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setNewEvent({ ...newEvent, image: e.target.files[0] })}
        />
        {editingId ? (
          <button onClick={updateEvent}>Update Event</button>
        ) : (
          <button onClick={addEvent}>Add Event</button>
        )}
      </div>
      <div className="event-cards">
        {events.map((event) => (
          <div key={event.id} className="event-card">
            <h3 className="event-name">{event.name}</h3>
            <p className="event-description">{event.description}</p>
            <p className="event-date"><strong>Date:</strong> {new Date(event.date).toLocaleString()}</p>
            <p className="event-location"><strong>Location:</strong> {event.location}</p>
            {event.image && <img className="event-image" src={event.image} alt={event.name} />}
            <div className="event-buttons">
              <button className="edit-button" onClick={() => handleEditClick(event)}>Update</button>
              <button className="delete-button" onClick={() => deleteEvent(event.id)}>Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventAdminPanel;
