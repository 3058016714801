import React, { useState, useEffect } from "react";
import { db } from "../firebase"; // Import Firestore
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";
import '../styles/social.css';  // Import the CSS

const SocialMediaPanel = () => {
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);
  const [newSocialMedia, setNewSocialMedia] = useState({ name: "", link: "", icon: null });
  const [editingId, setEditingId] = useState(null); // State to track the ID of the social media being edited

  useEffect(() => {
    const fetchSocialMediaLinks = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "socialMedia"));
        setSocialMediaLinks(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error("Error fetching social media links: ", error);
      }
    };

    fetchSocialMediaLinks();
  }, []);

  const handleIconUpload = async (file) => {
    try {
      if (!file.type.startsWith("image/")) {
        alert("Please select a valid image file.");
        return null;
      }

      // Convert image to base64 or store as a binary in Firestore
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result);  // On success, resolve with the base64 URL
        reader.onerror = reject;  // On error, reject the promise
      });
    } catch (error) {
      console.error("Error processing icon: ", error);
      return null;
    }
  };

  const addSocialMediaLink = async () => {
    try {
      let iconURL = null;

      if (newSocialMedia.icon) {
        iconURL = await handleIconUpload(newSocialMedia.icon);
      }

      const socialMediaData = { name: newSocialMedia.name, link: newSocialMedia.link, icon: iconURL };
      const docRef = await addDoc(collection(db, "socialMedia"), socialMediaData);
      setSocialMediaLinks([...socialMediaLinks, { id: docRef.id, ...socialMediaData }]);
      setNewSocialMedia({ name: "", link: "", icon: null });
    } catch (error) {
      console.error("Error adding social media link: ", error);
    }
  };

  const updateSocialMediaLink = async () => {
    try {
      let iconURL = null;
      if (newSocialMedia.icon) {
        iconURL = await handleIconUpload(newSocialMedia.icon);
      }

      const updatedData = { 
        name: newSocialMedia.name, 
        link: newSocialMedia.link, 
        icon: iconURL || newSocialMedia.icon 
      };

      await updateDoc(doc(db, "socialMedia", editingId), updatedData);
      setSocialMediaLinks(socialMediaLinks.map((sm) => (sm.id === editingId ? { id: editingId, ...updatedData } : sm)));
      setNewSocialMedia({ name: "", link: "", icon: null });
      setEditingId(null); // Reset the editing ID
    } catch (error) {
      console.error("Error updating social media link: ", error);
    }
  };

  const deleteSocialMediaLink = async (id) => {
    try {
      await deleteDoc(doc(db, "socialMedia", id));
      setSocialMediaLinks(socialMediaLinks.filter((sm) => sm.id !== id));
    } catch (error) {
      console.error("Error deleting social media link: ", error);
    }
  };

  const handleEditClick = (link) => {
    setEditingId(link.id);
    setNewSocialMedia({ name: link.name, link: link.link, icon: link.icon });
  };

  return (
    <div>
      <h1>Social Media Admin Panel</h1>
      <div className="social-media-form">
        <input
          type="text"
          placeholder="Social Media Name"
          value={newSocialMedia.name}
          onChange={(e) => setNewSocialMedia({ ...newSocialMedia, name: e.target.value })}
        />
        <input
          type="text"
          placeholder="Link"
          value={newSocialMedia.link}
          onChange={(e) => setNewSocialMedia({ ...newSocialMedia, link: e.target.value })}
        />
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setNewSocialMedia({ ...newSocialMedia, icon: e.target.files[0] })}
        />
        {editingId ? (
          <button onClick={updateSocialMediaLink}>Update Social Media</button>
        ) : (
          <button onClick={addSocialMediaLink}>Add Social Media</button>
        )}
      </div>
      <div className="social-media-cards">
        {socialMediaLinks.map((link) => (
          <div className="social-media-card" key={link.id}>
            <span className="name">{link.name}</span>
            {link.icon && <img src={link.icon} alt={link.name} className="icon" />}
            <div className="link">
              <a href={link.link} target="_blank" rel="noopener noreferrer">Go to Link</a>
            </div>
            <div className="social-media-buttons">
              <button className="edit-button" onClick={() => handleEditClick(link)}>Update</button>
              <button className="delete-button" onClick={() => deleteSocialMediaLink(link.id)}>Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SocialMediaPanel;
