import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";
import '../styles/Reader.css';

const ReaderOpinionAdmin = () => {
  const [opinions, setOpinions] = useState([]);
  const [formData, setFormData] = useState({ quote: "", author: "" });
  const [editingOpinionId, setEditingOpinionId] = useState(null);
  const db = getFirestore();

  useEffect(() => {
    fetchOpinions();
  }, []);

  const fetchOpinions = async () => {
    const snapshot = await getDocs(collection(db, "readerOpinions"));
    const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setOpinions(data);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const opinionData = {
      quote: formData.quote,
      author: formData.author,
    };

    if (editingOpinionId) {
      await updateDoc(doc(db, "readerOpinions", editingOpinionId), opinionData);
      setEditingOpinionId(null);
    } else {
      await addDoc(collection(db, "readerOpinions"), opinionData);
    }

    fetchOpinions();
    setFormData({ quote: "", author: "" });
  };

  const handleEdit = (opinion) => {
    setEditingOpinionId(opinion.id);
    setFormData({ quote: opinion.quote, author: opinion.author });
  };

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, "readerOpinions", id));
    fetchOpinions();
  };

  return (
    <div>
      <h2>{editingOpinionId ? "Edit" : "Add"} Reader Opinion</h2>
      <form onSubmit={handleSubmit}>
        <textarea
          name="quote"
          value={formData.quote}
          onChange={handleInputChange}
          placeholder="Quote"
          required
        />
        <input
          type="text"
          name="author"
          value={formData.author}
          onChange={handleInputChange}
          placeholder="Author"
          required
        />
        <button type="submit">{editingOpinionId ? "Update" : "Add"} Opinion</button>
      </form>

      <h2>Reader Opinions</h2>
      <div className="reader-opinions-container">
        {opinions.map((opinion) => (
          <div className="reader-opinion-card" key={opinion.id}>
            <blockquote>"{opinion.quote}"</blockquote>
            <p>- {opinion.author}</p>
            <div className="reader-opinion-buttons">
              <button className="edit-button" onClick={() => handleEdit(opinion)}>Edit</button>
              <button className="delete-button" onClick={() => handleDelete(opinion.id)}>Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReaderOpinionAdmin;
