import React, { useState } from "react";
import { updatePassword, signOut } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import "../styles/changePassword.css";

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswords, setShowPasswords] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleChangePassword = async (e) => {
    e.preventDefault();

    // Validate passwords
    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      setSuccessMessage("");
      return;
    }

    try {
      const user = auth.currentUser;
      if (user) {
        // Update the password
        await updatePassword(user, newPassword);
        setSuccessMessage("Password updated successfully. Redirecting to login...");
        setErrorMessage("");

        // Log the user out after a short delay
        setTimeout(async () => {
          await signOut(auth);
          navigate("/"); // Redirect to the login page ("/")
        }, 2000); // 2 seconds delay for user feedback
      } else {
        setErrorMessage("User is not authenticated");
      }
    } catch (error) {
      setErrorMessage("Failed to update password: " + error.message);
      setSuccessMessage("");
    }
  };

  return (
    <div className="change-password-container">
      <h2>Change Password</h2>
      <form onSubmit={handleChangePassword}>
        <div className="password-field">
          <input
            type={showPasswords ? "text" : "password"}
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="password-field">
          <input
            type={showPasswords ? "text" : "password"}
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <span
          className="toggle-password-visibility"
          onClick={() => setShowPasswords((prev) => !prev)}
        >
          {showPasswords ? "👁️" : "🙈"}
        </span>
        <button type="submit">Update Password</button>
      </form>
      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default ChangePassword;
