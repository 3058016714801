import React, { useState, useEffect } from "react";
import { db } from "../firebase"; // Import Firestore
import { collection, getDocs, addDoc, deleteDoc, doc } from "firebase/firestore";
import "../styles/kindle.css"; // Import the CSS for Kindle theme

const KindleManager = () => {
  const [books, setBooks] = useState([]);
  const [newBook, setNewBook] = useState("");

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "kindleBooks"));
        setBooks(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error("Error fetching books: ", error);
      }
    };

    fetchBooks();
  }, []);

  const addBook = async () => {
    if (!newBook) {
      alert("Please enter a valid book link.");
      return;
    }

    try {
      const bookData = { link: newBook };
      const docRef = await addDoc(collection(db, "kindleBooks"), bookData);
      setBooks([...books, { id: docRef.id, ...bookData }]);
      setNewBook("");
    } catch (error) {
      console.error("Error adding book: ", error);
    }
  };

  const deleteBook = async (id) => {
    try {
      await deleteDoc(doc(db, "kindleBooks", id));
      setBooks(books.filter((book) => book.id !== id));
    } catch (error) {
      console.error("Error deleting book: ", error);
    }
  };

  return (
    <div>
      <h1>Kindle Book Manager</h1>
      <div className="book-form">
        <input
          type="text"
          placeholder="Enter Book Link"
          value={newBook}
          onChange={(e) => setNewBook(e.target.value)}
        />
        <button onClick={addBook}>Add to Kindle</button>
      </div>
      <div className="book-list">
        {books.map((book) => (
          <div className="book-item" key={book.id}>
            <a href={book.link} target="_blank" rel="noopener noreferrer">
              {book.link}
            </a>
            <button className="delete-button" onClick={() => deleteBook(book.id)}>
              Remove
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KindleManager;
